@import url("https://rsms.me/inter/inter.css");

@import "./bootstrap";
@import "./colors";
@import "./theme";

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  min-width: 612px;
}

body {
  height: 100vh;
}

a {
  color: $color-black;
  font-weight: 600;
  text-decoration: none;
}

// Mat Buttons .mat-raised-button,
.mat-stroked-button {
  border-radius: 20px !important;
  padding: 8px 16px !important;
  line-height: 24px !important;
  box-shadow: none !important;
}

// Mat Form Field

.mat-form-field-flex > .mat-form-field-infix {
  padding: 0.4em 0 !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-flex {
  border-radius: 4px !important;
  padding: 8px 12px !important;
  background-color: white !important;
  border: 1px solid map-get($palette-primary, 400);
}

.mat-form-field-suffix {
  color: map-get($palette-primary, 400);
}

.small-input {
  .mat-form-field-infix {
    border: none !important;
  }

  .mat-form-field-flex {
    align-items: center !important;
  }

  .mat-form-field-wrapper {
    height: 40px !important;
    padding-bottom: 0 !important;
  }
}

// Toggle
.mat-slide-toggle-thumb {
  background-color: $color-white !important;
}

.mat-checked .mat-slide-toggle-bar {
  background-color: $color-green !important;
}

.mat-slide-toggle-bar {
  width: 40px !important;
  height: 24px !important;
  border-radius: 12px !important;
}

.mat-slide-toggle-thumb-container {
  top: 2px !important;
  left: 2px !important;
}

button.mat-raised-button {
  &:hover {
    background-color: $color-primary-light;

    &.mat-primary {
      background-color: $color-primary-grey;
    }
  }
}

button.mat-button,
button.mat-raised-button {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  border-radius: 160px;
  padding: 0 20px;
}

button.mat-icon-button {
  padding: 0;
}

a.link:hover {
  cursor: pointer;
}

mat-form-field.mat-form-field-invalid .mat-form-field-flex {
  border-color: $color-red !important;
}

mat-form-field.edit-field {
  * {
    cursor: pointer;
  }

  mat-icon {
    transform: translateY(6px);
  }
}

.mat-dialog-container {
  background: $color-white;
  border: 1px solid map-get($palette-primary, 300);
  box-sizing: border-box;
}

.success-snackbar {
  color: $color-white !important;
  background-color: $color-primary-dark;
  margin-bottom: 50px !important;
}

.error-snackbar {
  color: $color-white !important;
  background-color: $color-error !important;
  margin-bottom: 50px !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.btnspinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid $color-white;
  border-top-color: $color-black;
  animation: spinner 0.8s linear infinite;
}

.mat-dialog-content {
  max-height: initial;
}

.dialog-head.mat-dialog-title {
  display: flex;
  justify-content: space-between;
  position: static;
  width: 100%;
  height: 32px;
  margin-bottom: 30px;

  & > .dialog-title h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 0 auto;
  }

  & > .dialog-close {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
      & img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
